import { NextPageWithLayout } from '@scorenco/next';

import { WidgetsLayout } from '../layouts';

const Home: NextPageWithLayout = () => {
  if (typeof window !== 'undefined')
    window.location.href = 'https://scorenco.com/clubs/widgets';

  return <div>TODO</div>;
};

export const getStaticProps = WidgetsLayout.buildGetStaticProps();

Home.getLayout = WidgetsLayout.buildGetLayout();

export default Home;
